import { Component, markRaw } from "vue";
import { AnalyticsIcon, BannerIcon, ChatIcon, ClientIcon, ClockIcon, EducationIcon, FinanceIcon, LinkIcon, OrderIcon, PartnerIcon, SettingIcon, ShopIcon, StoreIcon } from '@/plugins/icons';
import { ROLES } from "./enums";

export type Navigation = {
  name: string,
  route: string,
  roles: Array<ROLES> | null,
  icon?: Component
}

const NAVIGATION = {
  main: [
    { name: "Главная", route: "/", roles: null },
    { name: "Каталог", route: "/catalog", roles: null },
    { name: "Партнерам", route: "/partner", roles: null },
    { name: "О компании", route: "/about", roles: null },
  ],
  admin: [
    { icon: markRaw(ShopIcon), name: "Магазин", route: "/admin/catalog", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(StoreIcon), name: "Склад", route: "/admin/store", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(OrderIcon), name: "Заказы", route: "/admin/order", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(PartnerIcon), name: "Партнеры", route: "/admin/partner", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(ClientIcon), name: "Клиенты", route: "/admin/client", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(SettingIcon), name: "Настройки", route: "/admin/setting", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(BannerIcon), name: "Баннеры", route: "/admin/banner", roles: [ROLES.OWNER, ROLES.ADMIN] },
    { icon: markRaw(ShopIcon), name: "Чаты", route: "/admin/chat", roles: [ROLES.OWNER, ROLES.ADMIN] },
  ],
  profile: [
    { icon: markRaw(ClockIcon), name: "История заказов", route: "/profile/order", roles: [ROLES.OWNER, ROLES.ADMIN, ROLES.PARTNER] },
    { icon: markRaw(AnalyticsIcon), name: "Аналитика", route: "/profile/analytics", roles: [ROLES.OWNER, ROLES.ADMIN, ROLES.PARTNER] },
    { icon: markRaw(LinkIcon), name: "Ссылки и клиенты", route: "/profile/link", roles: [ROLES.OWNER, ROLES.ADMIN, ROLES.PARTNER] },
    { icon: markRaw(FinanceIcon), name: "Финансы", route: "/profile/finance", roles: [ROLES.OWNER, ROLES.ADMIN, ROLES.PARTNER] },
    { icon: markRaw(EducationIcon), name: "Обучение", route: "/profile/education", roles: [ROLES.OWNER, ROLES.ADMIN, ROLES.PARTNER] },
  ],
}

export const currentNavigation = (section: 'main' | 'admin' | 'profile') => {
  return NAVIGATION[section] || [];
}