export const currentUserName = (name: string) => {
  const current = name.split(' ');
  return current[1];
}

export const currentUserFirstName = (name: string) => {
  const current = name.split(' ');
  return current[1][0];
}

export const currentFirstName = (name: string) => {
  return name[0];
}

export const onlineStatus = (lastOnline: Date) => {
  return Number(new Date()) - Number(new Date(lastOnline)) < 1000 * 60 * 5;
}

export const correctUserName = (name: string) => {
  const current = name.split(' ');
  return current[1];
};