export enum STATUSES {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  SERVER = 500,
}

export enum ROLES {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
  USER = 'USER',
}

export enum FILES_TYPES {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
};