import { ROLES } from "./enums"

export const HEADER_PROFILE_LIST = (role?: ROLES) => {
  const list = [
    { _id: '/profile', name: 'Профиль' },
    { _id: '/profile/order', name: 'История заказов' },
    { _id: '/logout', name: 'Выход' },
  ];
  if (role === ROLES.ADMIN || role === ROLES.OWNER) list.splice(2, 0, { _id: '/admin/catalog', name: 'Админ. панель' });
  return list;
}

export const HEADER_PROFILE_ADMIN_LIST = [
  { _id: '/', name: 'Сайт' },
  { _id: '/profile', name: 'Профиль' },
  { _id: '/logout', name: 'Выход' },
]

export const CATALOG_FILTER_SORT_LIST = [
  { _id: '', name: 'Отменить' },
  { _id: 'type_UNIT', name: 'Поштучно' },
  { _id: 'type_PACK', name: 'В упаковке' },
  { _id: 'price_max', name: 'По убыванию цены' },
  { _id: 'price_min', name: 'По возрастанию цены' },
  { _id: 'name_min', name: 'От А до Я' },
  { _id: 'name_max', name: 'От Я до А' },
]