
import { defineComponent } from 'vue-demi';
import AdminHeader from '@/components/admin/header/index.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: "admin-layout",
  computed: {
    ...mapGetters(['user']),
  },
  components: {
    AdminHeader
  }
});
