import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02e33c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (element) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        to: element.route,
        key: element.route,
        custom: ""
      }, {
        default: _withCtx(({ isExactActive, navigate }) => [
          _createElementVNode("li", {
            class: _normalizeClass(["flex items-center cursor als", { active: isExactActive }]),
            onClick: navigate
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(element.icon), { color: "#fff" })),
            _createElementVNode("span", null, _toDisplayString(element.name), 1)
          ], 10, _hoisted_2)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}