
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { currentUserName } from '@/utils/user';
import { DownArrowIcon } from '@/plugins/icons'
import { currentImageSize } from '@/utils/files';
import { HEADER_PROFILE_ADMIN_LIST } from "@/store/common/drop";

export default defineComponent({
  name: 'AdminProfile',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      showMenu: false,
      dropOptions: {
        id: 'header-profile-admin-action',
        list: HEADER_PROFILE_ADMIN_LIST,
        border: true,
        width: 110,
      },
    }
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    currentImageSize,
    switchShowMenu() {
      this.showMenu = !this.showMenu;
      this.$store.commit('createDropElement', { options: this.dropOptions, target: `.profile` });
    },
    // Emitter
    onEmits() {
      this.emitter.on(`header-profile-admin-action-drop`, this.selectDropElement);
    },
    offEmits() {
      this.emitter.off(`header-profile-admin-action-drop`, this.selectDropElement);
    },
    selectDropElement(element: { _id: string, name: string }) {
      this.$router.push(element._id);
      this.$store.commit('destroyDropElement');
    },
  },
  computed: {
    currentName() {
      return currentUserName(this.user.name);
    }
  },
  components: {
    DownArrowIcon
  }
})
