import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f51df80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile--avatar" }
const _hoisted_2 = {
  key: 0,
  alt: ""
}
const _hoisted_3 = { class: "profile--name als" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: "profile flex items-center cursor",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchShowMenu && _ctx.switchShowMenu(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.user.avatar)
        ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_2, null, 512)), [
            [_directive_lazy, _ctx.currentImageSize(_ctx.user.avatar.src, 50, 50)]
          ])
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentName), 1),
    _createVNode(_component_down_arrow_icon, {
      color: "#fff",
      class: _normalizeClass({ active: _ctx.showMenu })
    }, null, 8, ["class"])
  ]))
}