
import { currentNavigation } from "@/store/common/navigation";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "AdminNavigation",
  data() {
    return {
      navigation: currentNavigation("admin"),
    };
  },
});
