
import { defineComponent, PropType } from "vue-demi";
import { LogotypeIcon } from '@/plugins/icons';
import AdminNavigation from './Navigation.vue';
import AdminProfile from './Profile.vue';
import { User } from "@/interfaces/user/user.dto";

export default defineComponent({
  name: 'AdminHeader',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  components: {
    LogotypeIcon,
    AdminNavigation,
    AdminProfile
  }
})
